import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height custom-background",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mx-auto items-center",attrs:{"max-width":"380"}},[_c(VToolbar,{attrs:{"dark":"","flat":"","color":"primary"}},[_c(VSpacer),_c(VToolbarTitle,[_c('h1',{staticClass:"title"},[_c(VIcon,{staticClass:"mb-1 mr-2",attrs:{"dark":""}},[_vm._v("mdi-lock")]),_c('airviva-logo',{attrs:{"size":"100px"}}),_vm._v(" "+_vm._s(_vm.$t('AUTHENTICATION')))],1)]),_c(VSpacer)],1),_c(VCardText,{staticClass:"mt-8"},[_c(VTextField,{attrs:{"prepend-icon":"mdi-email","label":_vm.$tc('EMAIL', 1)},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"prepend-icon":"mdi-lock","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"rules":[_vm.rules.required],"label":_vm.$t('PASSWORD')},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardText,[_c('p',{staticClass:"text-center"},[_vm._v("Forgot your password?")])]),_c(VCardActions,{staticClass:"pa-0"},[_c(VBtn,{attrs:{"large":"","tile":"","color":"primary","block":"","dark":""},on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.$t('LOGIN'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }