<template>
  <v-container fluid class="fill-height custom-background">
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto items-center" max-width="380">
          <v-toolbar dark flat color="primary">
            <v-spacer></v-spacer>

            <v-toolbar-title>
            <h1 class="title">
                <v-icon dark class="mb-1 mr-2">mdi-lock</v-icon>
            <airviva-logo size="100px" />
                {{ $t('AUTHENTICATION') }}</h1>
            </v-toolbar-title>
            <v-spacer></v-spacer>

          </v-toolbar>

          <v-card-text class="mt-8">
            <v-text-field
              v-model="username"
              prepend-icon="mdi-email"
              :label="$tc('EMAIL', 1)">
            </v-text-field>

            <v-text-field
              v-model="password"
              prepend-icon= "mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[rules.required]"
              @click:append="showPassword = !showPassword"
              :label="$t('PASSWORD')">
            </v-text-field>
          </v-card-text>

          <v-card-text>
            <p class="text-center">Forgot your password?</p>
          </v-card-text>

          <v-card-actions class="pa-0">
            <v-btn large tile color="primary" @click="login" block dark>
              {{ $t('LOGIN') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
import store from '@/store.js'
import AirvivaLogo from '@/components/AirvivaLogo.vue';

export default {
  name: "login",
  components: {
    AirvivaLogo,
  },
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      rules: {
        required: value => !!value || 'Required.',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    };
  },
  methods: {
    login() {
      store.user = this.username;
      const redirectPath = this.$route.query.redirect || '/';
      this.$router.push(redirectPath);

    }
  },
}
</script>

<style scoped>
.custom-background {
  background-image: url('../assets/airviva/bg9.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
}

</style>
